import React, { useEffect, useState, useRef, useMemo } from "react"
import { Helmet } from "react-helmet"
import Modal from "react-modal"
import { useMediaPredicate } from "react-media-hook"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import { Section, Container } from "../../../components/grid/grid"

import bgCanvas from "../../../assets/img/lucky-dip/background-canvas-2.jpeg"
import bgCanvasMobile from "../../../assets/img/lucky-dip/background-canvas-2.jpeg"

import cursor from "../../../assets/img/balloons/cursor.png"
import flyingKey1 from "../../../assets/img/lucky-dip/flying_key_1.png";
import flyingKey2 from "../../../assets/img/lucky-dip/flying_key_2.png";
import diskon100 from "../../../assets/img/lucky-dip/diskon_100.png";
import diskon125 from "../../../assets/img/lucky-dip/diskon_125.png";
import diskon150 from "../../../assets/img/lucky-dip/diskon_150.png";
import diskon175 from "../../../assets/img/lucky-dip/diskon_175.png";
import diskon200 from "../../../assets/img/lucky-dip/diskon_200.png";

import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

const axios = require("axios")

const SUBMIT_POP = gql`
  mutation SubmitFormLuckyDip(
    $fullName: String
    $phoneNumber: String
    $email: String
    $product: String
    $salesName: String
    $officeName: String
    $discount: String
    $payment: String
    $promo: String
  ) {
    submit2LuckyDip(
      input: {
        clientMutationId: ""
        fullName: $fullName
        phoneNumber: $phoneNumber
        email: $email
        product: $product
        salesName: $salesName
        officeName: $officeName
        discount: $discount
        payment: $payment
        promo: $promo
      }
    ) {
      status
      message
    }
  }
`

class Balloon {
  constructor(x, y, speed, value, popSize, imageSrc, imageSize, canvasHeight) {
    this.x = x;
    this.y = y;
    this.speed = speed;
    this.value = value;
    this.popSize = popSize;
    this.imageSrc = imageSrc;
    this.imageSize = imageSize;
    this.canvasHeight = canvasHeight;
  }

  draw(ctx) {
    const image = new Image(this.imageSize, this.imageSize);
    image.src = this.imageSrc;

    ctx.beginPath();
    ctx.arc(this.x, this.y, this.popSize, 0, Math.PI * 2);
    /* Draw the pop area */
    /* ctx.fillStyle = "red";
    ctx.fill(); */

    ctx.drawImage(
      image,
      this.x - this.imageSize / 2,
      this.y - this.imageSize / 2,
      image.width,
      image.height
    );

    /* Draw the value */
    /* ctx.fillStyle = "black";
    ctx.font = "16px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(this.value.value.toString(), this.x, this.y); */

    ctx.closePath();
  }

  update() {
    this.y -= this.speed;
    if (this.y + this.popSize < 0) this.y = this.canvasHeight + this.popSize; // Reset position if off-screen
  }
}

const mapImageSuccess = {
  "Additional Discount": {
    "1%": diskon100,
    "1.25%": diskon125,
    "1.5%": diskon150,
    "1.75%": diskon175,
    "2%": diskon200,
  },
};

const LuckyDipPopPage = (location) =>{
  const canvasRef = useRef(null);
  const canvasWrapRef = useRef(null);
  const [submitPop] = useMutation(SUBMIT_POP)
  const resizeScreen = useMediaPredicate("(max-width: 992px)")
  const [successModal, setSuccessModal] = useState(false)
  const [imageSuccess, setImageSuccess] = useState("")
  const [smsSendComplete, setSmsSendComplete] = useState(false)
  const [backgroundCanvas, setBackgroundCanvas] = useState(bgCanvas)
  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(30, 51, 62, 0.9)',
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  }
  const ogUrl = `${process.env.BASE_URL}${location.path ? location.path : ''}`
  const [phone, setPhone] = useState(null)

  const [canvasWidth, setCanvasWidth] = useState(null);
  const [canvasHeight, setCanvasHeight] = useState(null);

  useEffect(() => {
    setCanvasWidth(canvasWrapRef.current?.offsetWidth);
    setCanvasHeight(canvasWrapRef.current?.offsetHeight);
  }, [canvasWrapRef.current?.offsetWidth, canvasWrapRef.current?.offsetHeight]);
  
  const popSize = 50;
  const itemsArray = [
    { promo: "Additional Discount", value: "1%" },
    { promo: "Additional Discount", value: "1.25%" },
    { promo: "Additional Discount", value: "1.5%" },
    { promo: "Additional Discount", value: "1.75%" },
    { promo: "Additional Discount", value: "2%" },
  ].sort(() => Math.random() - 0.5);

  const imageArr = [flyingKey1, flyingKey2];
  const imageSize = 200;
  const minDraw = imageSize / 2;
  const maxDraw = canvasWidth - minDraw;
  const rangeDraw = maxDraw - minDraw;
  let animationFrameId;
  const [balloons, setBalloons] = useState([]);

  function submitData({data}) {
    const name = sessionStorage.getItem("customerName")
    const email = sessionStorage.getItem("customerEmail")
    const disc = data.value
    const product = sessionStorage.getItem("product")
    const salesName = sessionStorage.getItem("salesName")
    const officeName = sessionStorage.getItem("officeName")
    const formatNumber = phone.split("+").join("")
    const promo = data.promo

    submitPop({
      variables: {
        fullName: name,
        phoneNumber: formatNumber,
        email: email,
        product: product,
        salesName: salesName,
        officeName: officeName,
        discount: disc,
        payment: "",
        promo: promo,
      },
    })
    .then((res) => {
      sendSms({
        name,
        phone,
        disc,
        product,
        salesName,
        promo,
      })
      sessionStorage.removeItem("customerName")
      sessionStorage.removeItem("customerEmail")
      sessionStorage.removeItem("customerPhone")
      sessionStorage.removeItem("product")
      sessionStorage.removeItem("salesName")
      sessionStorage.removeItem("officeName")
      sessionStorage.removeItem("isAgree")
      sessionStorage.removeItem("promo")

      setPhone(null)
    })
    .catch((e) => console.log('Error submitData', e))
  }

  function sendSms(param) {
    // const url = "https://sinarmasland.com/api/sms/pop"
    const url = process.env.BASE_URL + "/api/sms/pop"
    const message = `Selamat Bapak/Ibu ${param.name}, Anda mendapatkan ${param.promo} sebesar ${param.disc} dari Program Lucky Dip Infinite Living Periode III Sinar Mas Land 2024. Simpan SMS ini sebagai bukti klaim.`
    var data = JSON.stringify({
      phoneNumber: param.phone,
      message: encodeURI(message),
    })
    const config = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: data,
    }

    axios(config)
    .then(function(response) {
      console.log(JSON.stringify(response.data))
      setSmsSendComplete(true)
    })
    .catch(function(error) {
      console.log(error)
    })
  }


  // Function to generate random balloon properties
  const createBalloon = (index) => {
    const x = Math.random() * rangeDraw + minDraw; // Draw within range, so it not cutout
    const y = canvasHeight + Math.random() * 100; // start from bottom of canvas
    const speed = Math.random() * 2 + 1; // Random speed between 1 and 3
    const value = itemsArray[index]; // Random value between 0 and 99
    const imageSrc =
      imageArr[Math.floor(Math.random() * (imageArr.length - 1 - 0 + 1) + 0)]; // generate random number between 0 and imageArr.length
    return new Balloon(
      x,
      y,
      speed,
      value,
      popSize,
      imageSrc,
      imageSize,
      canvasHeight
    );
  };

  useEffect(() => {
    let balloonsTimeout = [];
    if (canvasWidth && canvasHeight) {
      balloonsTimeout = itemsArray.map((_, index) => {
        return setTimeout(
          () => setBalloons((prev) => [...prev, createBalloon(index)]),
          index * 1000
        );
      });
    }
    return () => {
      balloonsTimeout.forEach(clearTimeout);
    }; // Cleanup on component unmount
  }, [canvasWidth, canvasHeight]);

  // Animation loop
  const animate = () => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.imageSmoothingQuality = "high";
    ctx.imageSmoothingEnabled = true;
    ctx.mozImageSmoothingEnabled = true;
    ctx.webkitImageSmoothingEnabled = true;
    ctx.msImageSmoothingEnabled = true;
    ctx.clearRect(0, 0, canvasWidth, canvasHeight);

    balloons.forEach((balloon) => {
      balloon.update();
      balloon.draw(ctx);
    });

    animationFrameId = window.requestAnimationFrame(animate);
  };

  useEffect(() => {
    if (canvasHeight && canvasHeight) {
      animate();
    }
    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [balloons, canvasWidth, canvasHeight]);

  useEffect(() => {
    // detect if user has input phone number in form
    setPhone(sessionStorage.getItem("customerPhone"))

    let navbarDesktop = document.getElementById("desktopNav").querySelectorAll(".nav-link")

    if(resizeScreen){
      setBackgroundCanvas(bgCanvasMobile)

      document.getElementsByClassName("logo-secondary")[1].style.display = `none`
      document.getElementsByClassName("logo-main")[1].style.display = `block`
    }else{
      document.getElementsByClassName("logo-secondary")[0].style.display = `none`
      document.getElementsByClassName("logo-main")[0].style.display = `block`

      for (let i = 0; i < navbarDesktop.length; i++) {
        navbarDesktop[i].style.color = "#000"
      }
    }

    document.querySelector("#lang-wrapper").classList.remove('d-flex')
    document.querySelector("#lang-wrapper").classList.add('d-none')
  }, [])

  const handleSuccessModal = () => {
    setSuccessModal(false)
    window.location.href = "/luckydip"
  }

  const handleClickCanvas = (event) => {
    if(!phone) return false;
    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    for (let i = balloons.length - 1; i >= 0; i--) {
      const dx = x - balloons[i].x;
      const dy = y - balloons[i].y;
      const distance = Math.sqrt(dx * dx + dy * dy);

      if (distance < balloons[i].popSize) {
        /* alert(`Balloon popped! Value: ${JSON.stringify(balloons[i].value)}`); */
        submitData({data: balloons[i].value})
        setImageSuccess(
          mapImageSuccess[balloons[i].value.promo][
            balloons[i].value.value
          ]
        );
        setSuccessModal(true);
        window.cancelAnimationFrame(animationFrameId); // Stop animation
        break;
      }
    }
  };

  return(
    <Layout location={location} currentLocation={location.uri}>
      <SEO 
        title={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        fbTitle={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        twitterTitle={`Lucky Dip Infinite Living Periode III, Dapatkan Tambahan Promonya`}
        description={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        fbDescription={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        twitterDescription={`Special Promo Sinar Mas Land! Mainkan Lucky Dip Infinite Living Periode III 2024 dan menangkan tambahan promo special dari Sinar Mas Land`}
        url={ogUrl}
        img={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
        fbImg={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
        twitterImg={`https://www.sinarmasland.com/app/uploads/2024/09/Infinite-Living-Periode-III.jpeg`}
      />
      <Helmet>
        <meta 
          name="Permissions-Policy" 
          content="autoplay=(self)"
        ></meta>
        <body className="bd-page" />
      </Helmet>
      <Section className="sectionSurprise m-0 p-0 text-center">
        <Container className="containerSurprise text-center pt-5 pb-5">
          <h1 style={{
            textWrap: 'balance',
            fontSize: '1.5rem'
          }}>
            Klik salah satu kunci Infinite Living untuk mendapatkan promo tambahan Lucky Dip
          </h1>
          {/* <canvas id="canvasBalloon" width="800" height="300"></canvas> */}  
          <div className="canvas-wrapper" ref={canvasWrapRef} style={{
            cursor: `url(${cursor}), auto`,
            backgroundImage: `url(${backgroundCanvas})`,
          }}>
            <canvas
              ref={canvasRef}
              width={canvasWidth}
              height={canvasHeight}
              onClick={handleClickCanvas}
            />
          </div>
        </Container>
      </Section>
      <Modal
        isOpen={successModal}
        style={customStyles}
        contentLabel="result modal"
        overlayClassName="modal-overlay-general"
      >
        <div className="modal-luckydip-success modal-body text-center">
          <img src={imageSuccess} alt="Selamat" />
          {smsSendComplete ?
            <button
              className="btn btn-primary btn-close-luckydip"
              onClick={handleSuccessModal}
            >
              &times;
            </button> 
          : null}
        </div>
      </Modal>
    </Layout>
  )
}
export default LuckyDipPopPage
